@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 20px;
    margin-left: 10px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f7f4ed;
    margin-left: 10px;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #bbbec7;
    border-radius: 100vh;
    border: 3px solid #f6f7ed;
    margin-left: 10px;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #bbbec7;
    margin-left: 10px;
  }
}

/*
@font-face {
  font-family: "Commons";
  src: local("Commons"), url("./assets/fonts/TT Commons Italic.otf") format("opentype");
}

@font-face {
  font-family: "Commons";
  src: local("Commons"), url("./assets/fonts/TT Commons Black.otf") format("opentype");
}
*/
